<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="bg-white flex-column ma-auto pa-10 radius-10">
				<div class="justify-space-between items-center under-line">
					<h6>지갑 송금</h6>
					<button
						@click="$emit('cancel')"
					><v-icon>mdi-close</v-icon></button>
				</div>
				<div class=" overflow-y-auto ">
					<table class="table  size-px-13 th-left">
						<col width="110px" />
						<col width="auto" />
						<tbody>
						<tr>
							<th>상점명</th>
							<td>{{ franchisee_info.shop_name }}</td>
						</tr>
						<tr>
							<th>상점 잔액</th>
							<td>{{ franchisee_info.balance | makeComma }}원</td>
						</tr>
						<tr>
							<th>송금 가능 금액</th>
							<td>{{ item_upper.user_balance | makeComma }} 원</td>
						</tr>

						<tr>
							<th>송금 금액</th>
							<td>
								<input v-model="item.send_amount" class="input-box" type="number" :rules="$rules.max(item, 'send_amount', 8)"/>
							</td>
						</tr>
						<tr>
							<th>메모</th>
							<td>
								<div class="justify-space-between">
									<div>
										<input v-model="item.memo" placeholder="메모" class="input-box" maxlength="50"/>
									</div>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<button
					class="mt-10 btn btn-primary"
					:disabled="is_send"

					@click="postSend"
				>송금</button>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "../Layout/PopupLayer";
	export default {
		name: 'FranchiseeSend'
		,
		components: {PopupLayer},
		props: ['user', 'franchisee_info']
		, data: function(){
			return {
				item: {
					send_amount: ''
					,
				}
				, item_upper: {
					user_balance: ''
				}
			}
		}
		, computed: {
			is_send: function(){
				let t = true
				if(this.item.send_amount > 0 && Number(this.item_upper.user_balance) > Number(this.item.send_amount)){
					t = false
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				this.$bus.$emit('on', true)
				try {
					const result = await this.$Axios({
						method: 'get'
						, url: 'user/getUserMainInfo'
					})

					if (result.success) {
						this.item_upper.user_balance = result.data.user_balance
					} else {
						this.$bus.$emit('notify', {type: 'error', message: result.message})
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					setTimeout( () => {
						this.$bus.$emit('on', false)
					}, 500)
				}
			}

			, postSend: async function(){

				try{
					if(confirm('상점 ' + this.franchisee_info.shop_name + '에게 ' + this.item.send_amount + '원 송금하시겠습니까?')){
						const result = await this.$Axios({
							method: 'post'
							, url: 'member/postSend'
							, data: {
								shop_uid: this.franchisee_info.shop_uid
								, amount: this.item.send_amount
								, memo: this.item.memo
							}
						})

						if (result.success) {
							this.$emit('click')
						} else {
							throw result.message
						}
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
		}
		, created() {
			this.getData()
		}
	}
</script>