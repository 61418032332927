<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="bg-white full-height flex-column justify-space-between radius-20">
				<div class="pa-10-20 under-line justify-space-between">
					<h5>{{ title }}</h5>
					<button
						@click="$emit('cancel')"
					><v-icon>mdi-close-circle</v-icon></button>
				</div>
				<div
					class="pa-20  overflow-y-auto"
					style=""
				>
					<div class="justify-space-between items-center">
						<h6>가맹점</h6>
						<span v-if="item.uid" class="color-red">계정정보 변경은 관리자에게 문의하세요</span>
					</div>
					<div><input v-model="item.account_name" class="input-box radius-10" placeholder="가맹점 이름을 입력하세요" maxlength="25" :disabled="item.uid"/></div>

					<div class="mt-10 justify-space-between items-center">
						<h6>휴대폰 번호</h6>
						<span class="size-px-12 color-red">계정 인증 및 가상계좌 발급용 / 중복불가</span>
					</div>
					<div><input v-model="item.account_phone_number" class="input-box radius-10" placeholder="-없이 숫자만 입력하세요" type="number" :rules="[$rules.max(item, 'account_phone_number', 11)]"  :disabled="item.uid"/></div>

					<div
						v-if="false"
						class="mt-10 justify-space-between items-center"
					>
						<h6>이메일 주소</h6>
						<span class="size-px-12 color-red">가상계좌 발급용 / 중복불가</span>
					</div>
					<div
						v-if="false"
					><input v-model="item.account_email" class="input-box radius-10" placeholder="이메일을 입력하세요"  :disabled="item.uid" /></div>

					<div
						v-for="(shop, s_index) in items_shop"
						:key="'shop_' + s_index"
						class="mt-30 top-line-dashed"
					>
						<div class="mt-10 justify-space-between items-center">
							<h6>상점 정보 </h6>
							<button
								v-if="s_index > 0"
								@click="deleteShop(shop, s_index)"
							><v-icon class="color-red">mdi-minus-circle</v-icon></button>
						</div>
						<div class="color-red">정산 정보와 동일하게 입력하세요. </div>

						<div class="mt-10"><input v-model="shop.shop_name" class="input-box radius-10" placeholder="상호를 입력하세요. 중복 불가" maxlength="30"/></div>

						<div class="mtb-10"><input v-model="shop.shop_ceo" class="input-box radius-10" placeholder="대표자명을 입력하세요" maxlength="20"/></div>

						<div><input v-model="shop.shop_tell" class="input-box radius-10" placeholder="연락처 (-없이 숫자만 입력하세요)" type="number" :rules="[$rules.max(shop, 'shop_tell', 11)]"/></div>

					</div>

					<div class="mt-10 text-right">
						<button
							class=""
							@click="addShop"
							:disabled="is_add"
						><v-icon large :class="is_add ? 'color-gray' : 'color-blue'">mdi-plus-circle</v-icon></button>
					</div>
				</div>
				<div
					class="mt-auto"
				>
					<button
						@click="postSave"
						class="btn btn-primary"
						:disabled="is_save"
					>저장</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>
<script>
import PopupLayer from "../Layout/PopupLayer";
export default {
	name: 'FranchiseeItem'
	, props: ['user', 'franchisee_info']
	, components: {PopupLayer}
	, data: function(){
		return {
			item: {
				uid: null
			}
			, items_shop: [
				{}
			]
			, max_shop: 20
		}
	}
	, computed: {
		is_add: function(){
			let t = false
			if(this.items_shop.length >= this.max_shop){
				t = true
			}
			return t
		}
		, is_save: function(){
			let t = true

			if(this.item.account_name && this.item.account_phone_number){
				for(let i = 0; i < this.items_shop.length; i++){
					if(this.items_shop[i].shop_name && this.items_shop[i].shop_ceo){
						t = false
					}else{
						t = true
						break
					}
				}
			}
			return t
		}
		, title: function(){
			let t = '가맹점 추가'
			if(this.item.uid){
				t = '가맹점 정보 수정'
			}
			return t
		}
	}
	, methods: {
		addShop: function(){
			if(this.items_shop.length < this.max_shop){
				this.items_shop.push({})
			}
		}
		, getData: async function(){
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'member/getFranchisee'
					,data: {
						franchisee_id: this.franchisee_info.account_id
					}
				})

				if(result.success){
					this.item = result.data.franchisee_info
					this.items_shop = result.data.shop_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postFranchisee: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'member/postFranchisee'
					,data: {
						account_name: this.item.account_name
						, phone_number: this.item.account_phone_number
						, email: this.item.account_email
						, shop_info: JSON.stringify(this.items_shop)
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFranchiseeModify: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'member/postFranchiseeModify'
					,data: {
						uid: this.item.uid
						, shop_info: JSON.stringify(this.items_shop)
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, deleteShop: function(item, index){
			if(item.uid){
				this.is_on_delete = true
			}else{
				this.items_shop.splice(index, 1)
			}
		}
		, postSave: function(){

			if(this.item.uid){
				this.postFranchiseeModify()
			}else{
				this.postFranchisee()
			}
		}
	}
	, created() {
		if(this.franchisee_info.account_id){
			this.getData()
		}
	}
}
</script>